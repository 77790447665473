import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Casebanner from 'components/Casebanner/Casebanner'
import { SwiftShopperProps } from 'components/Props/swift-shopper'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Partnerprofile from 'components/Partnerprofile/Partnerprofile'
import Caseintegrate from 'components/Caseintegrate/Caseintegrate'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Mobilesolutions from 'components/Mobilesolutions/Mobilesolutions'
import Twocolcontent from 'components/Twocolcontent/Twocolcontent'
import Shoppingexpsec from 'components/Shoppingexpsec/Shoppingexpsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Datainsightsec from 'components/Datainsightsec/Datainsightsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const SwiftShopperPage = styled.div``

const SwiftShopper = memo(props => {
  const { data } = props
  const { location } = props
  const item = props.data.techArchitechDia.childImageSharp.fluid

  const topDaiImg = props.data.microservicesArchitecture.childImageSharp.fluid
  const bottomDaiImg = props.data.etaJobs.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Swift Shopper"
        description="Swift Shopper approached Simform to work together on building a platform that helps retailers improve shoppers' experience by providing an easy-to-use mobile app. "
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <SwiftShopperPage>
        <Casebanner
          bannerdata={SwiftShopperProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={SwiftShopperProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Partnerprofile
            partnerprofiledata={SwiftShopperProps.PartnerProfileProps}
          />
          <Caseintegrate caseintegratedata={SwiftShopperProps} {...props} />
        </div>
        {showContent && (
          <>
            <Caseresultsec
              caseresultsecdata={SwiftShopperProps}
              {...props}
              showImages
            />
            <Mobilesolutions mobilesolutiondata={SwiftShopperProps} />
            <Twocolcontent
              twocolcontentdata={SwiftShopperProps}
              colTopDaigram={topDaiImg}
              colBottomDaigram={bottomDaiImg}
              {...props}
            />
            <Shoppingexpsec
              shoppingexpsecdata={SwiftShopperProps}
              {...props}
              showTopRightImg={true}
              showBottomLeftImg={true}
              showBottomRightImg={true}
            />
            <Casecontentfullwimg
              casecontentfullwdata={SwiftShopperProps.casecontentfullwProps}
              fluid={item}
            />
            <Datainsightsec datainsightsecdata={SwiftShopperProps} {...props} />
            <Relatedcaselist
              relatedcaselistdata={SwiftShopperProps}
              {...props}
            />
            <Casecontactcardinfo Casecontactcardinfodata={SwiftShopperProps} />
          </>
        )}
      </SwiftShopperPage>
    </Layout>
  )
})

export default SwiftShopper

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/swiftbanner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    integrateImg: file(
      relativePath: { regex: "/integrating-with-ss-pmo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    microservicesArchitecture: file(
      relativePath: { regex: "/microservices-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    etaJobs: file(relativePath: { regex: "/eta-jobs@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    samsClub: file(relativePath: { regex: "/sams-club@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    pcCart: file(relativePath: { regex: "/pc-cart@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    shoppingDb: file(relativePath: { regex: "/shopping-db@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 717) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/tech-architech-dia@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    splashImageFull: file(
      relativePath: { regex: "/splash-bg-image-full@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 533) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/freewire-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/stubbswoottons-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
